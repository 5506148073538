/* app/javascript/devise_sessions_new.js */
/* discerns the visitor signup from the user login */
if (typeof jQuery !== 'undefined') {
  $(document).ready(function() {
    window.togglePassword = function() {
      var passwordField = document.getElementById('user_password');
      if (passwordField.type === 'password') {
        passwordField.type = 'text';
      } else {
        passwordField.type = 'password';
      }
    }
  });
} else {
  console.error('jQuery is not loaded');
}